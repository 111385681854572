
:root {
  --main-bg-color: rgba(48, 0, 0, 0.808);
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ol ol {
  margin-left: 15px;
}

h1 {
  font-family: 'Euphoria Script', Helvetica, sans-serif;
  font-size: 4rem;
  margin-bottom: 30px;  
  line-height: 4rem;
  font-weight: normal;
}

p {
  margin: 5px 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #dfc15e;
  background-color: #000;
  line-height: 1.5rem;
}

a {
  color: #dfc15e;
  text-decoration: none;
  display: block;
}

section {
  display: flex;
  justify-content: center;
}

#homePage {
  width:100%;
  height:100%;
  background-color: black;
  display: flex;
  flex-direction: column;
}

#homePage section:nth-child(even){
  background-color:var(--main-bg-color);
}

#ruleSection {
  font-size: 0.7rem;
  line-height: 1rem;
}
#ruleSection h1 {
  display: block;
}

.navWrapper {
  display: flex;
  position: absolute;
  right: 0px;
  top: 0px;
  background-color:var(--main-bg-color);
  width: 100%;
  opacity: 0.9;
}

.navWrapper svg {
  display: none;
}

nav {
  min-height: 40px;

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1em;
  text-transform: uppercase;
  font-weight: bold;
}

nav ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
nav ul li a {
  transition: all 215ms ease-in-out;
  padding: 15px;
  cursor: pointer;
}
nav ul li a:hover{
  color: red;
  border-radius: 5px;
}

#landingSection {
  width:100%;
  height:100vh;
  padding: 0;
  background-image: url('./assets/picture/bar1.jpg');
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  position: relative;
  background-attachment: fixed;
}

#darkOverlay {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.6);
  align-items: center;
}

#logoContainer {
  display: flex;
  flex-direction: column;
}

.logoImage {
  width: 18em;
  height: 18em;
  animation: pulseAnimation 1s;
}
.logoImage:hover {
  animation: pulseAnimation 1s ease-out;
  animation-fill-mode:none;
}

.section {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  padding: 40px 0;
  margin-left: 15px;
}

.sectionCol {
  display: flex;
  flex: 50%;
  flex-direction: column;
}

.sectionCol div {
  border: 0;
}

.jcc {justify-content: center;}
.aic {align-items: center;}
.tac {text-align: center;}

.pl15 {padding-left: 15px; }
.pl30 {padding-left: 30px; }
.pr15 {padding-right: 15px;}
.pr30 {padding-right: 30px;}

.mb15 {margin-bottom: 30px;}
.mb30 {margin-bottom: 30px;}


.tmp {
  position: absolute;
  content: "";
  background-color: #E67E22;
  height: 3px;
  width: 40px;
  bottom: -1px;
  left: 0;
}

.columnSection {
  display: flex;
}

.sectionBlock {
  display: flex;
  flex: 30.33%;
  flex-direction: column;
  font-size: 16px;
  margin-right: 20px;
  margin-bottom: 40px;
}

.sectionBlock h2 {
  text-transform: uppercase;
  font-size: 1.1rem;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;
  letter-spacing: 2px;
}

.sectionBlock h2::after {
  position: absolute;
  content: "";
  background-color: #dfc15e;
  height: 3px;
  width: 40px;
  bottom: -1px;
  left: 0;
}

.pfr {
  display: flex;
  background-color: #fff;
}
.pfr div {
  display: flex;
  max-height: 100px;
}
.pfr img {
  object-fit: contain;
  width: 100%;
}

/* svg */


svg {
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 1.5;
  cursor:pointer;
  position: absolute;
  top: 0;
}

svg path {
  fill: none;
  stroke: white;
  stroke-width: 1px;
}

svg + svg {
  margin-left: 1.5rem;
}

.icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  align-items: center;
}

#top,
#bottom {
  stroke-dasharray: 30, 75.39;
  transition: all 0.6s cubic-bezier(0.6, 0.33, 0.67, 1.29);
}

svg.active #top,
svg.active #bottom {
  stroke-dasharray: 75.39;
  stroke-dashoffset: -60;
}

svg:nth-child(2) {
  transform: rotate(0deg);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

svg:nth-child(2) path {
  transition: all 0.4s ease-in-out 0.6s;
}

svg:nth-child(2).active {
  transform: rotate(180deg);
}

.rocket #top {
  stroke-dasharray: 30, 88;
}


@media screen and (max-width: 500px) {
  nav {
    min-height: auto;
  }
  nav ul {
    flex-direction: column;
  }
  nav ul li a {
    transition: all 215ms ease-in-out;
    padding: 20px 35px;
  }
  .navWrapper svg {
    display: block;
  }
  .columnSection {
    display: flex;
    flex-direction: column;
  }
  .sectionCol {
    flex: 100%;
  }

  .sectionBlock {
    flex: auto;
  }
  }
.karaokeSectionLeft {

  animation-name: karaokeSectionLeft;
  animation-duration: 10s;
}

@keyframes karaokeSectionLeft {
  0% {
    opacity: 0; 
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulseAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.04);
  }
  100% {
    transform: scale(1);
  }
}

.eventLinks {
  display: inline;
}
#ruleSection > .section > ol > li > ul > li {
  margin-left: 10px;
}